<template>
    <div class="container">
        <slot name="registrationType"></slot>
        <h1 class="mt-0 mb-5">{{$t('vop.title')}}</h1>

        <div v-if="saving" class="my-5 alert alert-primary text-center">
            <b-spinner variant="aspena-blue" small /> {{$t('vop.submissionProcessing')}}
        </div>
        <div v-else>
            <div class="embed-responsive embed-responsive-4by3">
                <embed :src="`${$t('vop.vopLinkHref')}#toolbar=0`" class="embed-responsive-item shadow-sm border border-gray" type="application/pdf"/>
            </div>
            <div class="mt-4">
                <b-form-checkbox
                    v-model="vop"
                >
                    {{$t('vop.vop')}} <a :href="$t('vop.vopLinkHref')" target="_blank" rel="noopener">{{$t('vop.vopLinkText')}}</a>
                </b-form-checkbox>
            </div>

            <div class="mt-4 text-right">
                <aspena-btn
                    class="mr-3"
                    variant="aspena-blue"
                    @click.prevent="onCancel"
                >
                    {{$t('vop.cancel')}}
                </aspena-btn>

                <aspena-btn
                    class="mr-3"
                    variant="aspena-orange"
                    @click.prevent="onSubmit"
                    :disabled="!isValid"
                >
                    {{$t('vop.submit')}}
                </aspena-btn>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'VopPage',
    data () {
        return {
            saving: false,
            vop: false
        }
    },
    computed: {
        isValid () {
            return this.vop
        }
    },
    mounted () {
        this.$nextTick(() => {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 200)
        })
    },
    methods: {
        onTypesValid (value) {
            this.typesValid = value === true
        },
        onSubmit () {
            if (!this.vop) {
                return
            }
            this.saving = true
            this.$api.submissions.create(this.$store.state.userData)
                .then(() => {
                    this.$store.commit('clearUserData')
                    this.$router.replace({ name: 'Index', params: { wasSent: true } })
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error('vop.cannotSendDataset')
                }).finally(() => {
                    this.saving = false
                })
        },
        onCancel () {
            this.$router.push({ name: 'documents' })
        }
    }
}
</script>
